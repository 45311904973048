import { Tag } from "antd";
import {
  stopAllTrade,
  autoLogin,
  resetUser,
  shutdownAll,
} from "./../../utils/api";
// eslint-disable-next-line
export default {
  actions: [
    {
      name: "Auto Login",
      title: "Auto Login active users",
      description: "Are you sure to auto login for all ?",
      isDanger: false,
      callBack: autoLogin,
    },
    {
      name: "Reset and Start",
      title: "Reset all the trade",
      description: "Are you sure to reset  all ?",
      isDanger: false,
      callBack: resetUser,
    },
    {
      name: "Stop All",
      title: "Stop all the trades",
      description: "Are you sure to Stop all ?",
      isDanger: true,
      callBack: stopAllTrade,
    },
    {
      name: "Shutdown all Server",
      title: "Shutdown all server",
      description: "Are you sure to Shutdown all ?",
      isDanger: true,
      callBack: shutdownAll,
    },
  ],
  column: [
    {
      title: "Sl",
      dataIndex: "sl",
      key: "name",
      render: (_id, _data, idx) => <span>{idx + 1}</span>,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (data) => data.charAt(0).toUpperCase() + data.slice(1),
    },
    {
      title: "UserId",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Current Status",
      dataIndex: "isLive",
      key: "isLive",
      render: (data) => (
        <Tag
          style={{ width: "60px", textAlign: "center" }}
          color={data ? "green" : "red"}
        >
          {data ? "Live" : "Offline"}
        </Tag>
      ),
    },
    {
      title: "Max Profit",
      dataIndex: "maxProfit",
      key: "maxProfit",
      render: (data) => <span className="profit">{data.toLocaleString()}</span>,
    },
    {
      title: "Max Loss",
      dataIndex: "maxLoss",
      key: "maxLoss",
      render: (data) => <span className="loss">{data.toLocaleString()}</span>,
    },
    {
      title: "P&L",
      dataIndex: "total",
      key: "total",
      width: 150,
      render: (data) => {
        return data ? (
          <span className={data < 0 ? "loss" : "profit"}>
            {data >= 0 ? `+${data.toLocaleString()}` : data.toLocaleString()}
          </span>
        ) : (
          "-"
        );
      },
    },
  ],
};
