import { Button, Table, Form, Modal } from "antd";
import "./style.css";
import { useEffect, useState } from "react";
import { createUsers, deleteUser, listUsers } from "../../utils/api";
import C from "./constant";
import { CreateForm } from "./forms/createform";

function Users() {
  const [user, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    async function fetch() {
      const user = await listUsers();
      setLoading(false);
      setUsers(user);
    }
    fetch();
  }, []);

  const handleSubmit = async (payload) => {
    setLoading(true);
    await createUsers(payload);
    const users = await listUsers();
    form.resetFields();
    setUsers(users);
    setShow(false);
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    await deleteUser(id);
    const user = await listUsers();
    setUsers(user);
    setLoading(false);
  };

  const handleEdit = (id) => {
    form.setFieldsValue(user.find((i) => i._id === id));
    setShow(true);
  };

  return (
    <div className="user-container">
      <div className="user-sub-container">
        <div className="user-list">
          <Button
            type="primary"
            className="create"
            onClick={() => setShow(true)}
          >
            Create Users
          </Button>
        </div>
      </div>
      <div className="table-content">
        <Table
          rowKey={"_id"}
          className="table"
          loading={loading}
          dataSource={user}
          columns={C.column(handleEdit, handleDelete)}
          pagination={false}
        />
      </div>
      <Modal
        title={"User Form"}
        open={show}
        onOk={() => form.submit()}
        onCancel={() => {
          setShow(false);
          form.resetFields();
        }}
        destroyOnClose
      >
        <div className="order-form-container">
          <CreateForm form={form} handleSubmit={handleSubmit} />
        </div>
      </Modal>
    </div>
  );
}

export default Users;
