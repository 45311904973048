import { Tag, Button, Popconfirm } from "antd";

// eslint-disable-next-line
export default {
  column: (handleEdit, handleDelete) => [
    {
      title: "Sl",
      dataIndex: "sl",
      key: "name",
      render: (_id, _data, idx) => <span>{idx + 1}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data) => data?.charAt(0)?.toUpperCase() + data?.slice(1),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (data) => data?.charAt(0)?.toUpperCase() + data?.slice(1),
    },
    {
      title: "UserId",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Current Status",
      dataIndex: "isLive",
      key: "isLive",
      render: (data) => (
        <Tag
          style={{ width: "60px", textAlign: "center" }}
          color={data ? "green" : "red"}
        >
          {data ? "Live" : "Offline"}
        </Tag>
      ),
    },
    {
      title: "Max Profit",
      dataIndex: "maxProfit",
      key: "maxProfit",
      render: (data) => (
        <span className="profit">{data?.toLocaleString()}</span>
      ),
    },
    {
      title: "Max Loss",
      dataIndex: "maxLoss",
      key: "maxLoss",
      render: (data) => <span className="loss">{data?.toLocaleString()}</span>,
    },
    {
      title: "Base Quantity",
      dataIndex: "baseQuantity",
      key: "baseQuantity",
    },
    {
      title: "-",
      dataIndex: "_id",
      key: "_id",
      render: (data) => (
        <Button type="primary" onClick={() => handleEdit(data)}>
          Edit
        </Button>
      ),
    },
    {
      title: "-",
      dataIndex: "_id",
      key: "_id",
      render: (data) => (
        <Popconfirm
          title={"Deleting User"}
          description={"Are you Sure to Delete?"}
          onConfirm={() => handleDelete(data)}
          okText="Yes"
        >
          <Button type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ],
};
