import { Form, Button, Input } from "antd";
import { GoogleLogin } from "@react-oauth/google";
import { login, googleLogin } from "./../../utils/api";
import cIcon from "./assets/company.svg";
import "./style.css";

function Login() {
  return (
    <div className="login-container">
      <div className="login-modal">
        <div className="login-header">
          <img src={cIcon} className="company-logo" alt="log" />
          <h4 className="company-name">Stockbot AI</h4>
        </div>
        <div className="login-form">
          <Form
            name="loginForm"
            onFinish={async (payload) => {
              await login(payload);
            }}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input className="login-text" placeholder="Enter you username" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password placeholder="Enter you password" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="social-login">
          <GoogleLogin
            className="google-auth"
            shape={"square"}
            useOneTap
            onSuccess={async (cred) => {
              await googleLogin(cred);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
