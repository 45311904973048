import { Form, Input, InputNumber, Select } from "antd";

export function CreateForm({ form, handleSubmit }) {
  return (
    <Form
      form={form}
      name="loginForm"
      onFinish={handleSubmit}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
    >
      <Form.Item
        name={"order"}
        label="Select Order "
        rules={[
          {
            required: true,
            message: "Order is required",
          },
        ]}
      >
        <Select placeholder="Select Order">
          <Select.Option value="BUY">BUY</Select.Option>
          <Select.Option value="SELL">SELL</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name={"type"}
        label="Select Type "
        rules={[
          {
            required: true,
            message: "Type is required",
          },
        ]}
      >
        <Select placeholder="Select Type">
          <Select.Option value="CE">CE</Select.Option>
          <Select.Option value="PE">PE</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name={"symbol"}
        label="Select Index "
        rules={[
          {
            required: true,
            message: "Index is required",
          },
        ]}
      >
        <Select placeholder="Select Index">
          <Select.Option value="BANKNIFTY">BANKNIFTY</Select.Option>
          <Select.Option value="NIFTY">NIFTY</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="expiry"
        label="Enter Expiry"
        rules={[
          {
            required: true,
            message: "Please add expiry!",
          },
        ]}
      >
        <Input placeholder="Enter your expiry" />
      </Form.Item>

      <Form.Item
        name="variance"
        label="Enter B.Var"
        rules={[
          {
            required: true,
            message: "Please add B-Variance!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your B-Variance"
        />
      </Form.Item>
      <Form.Item
        name="quantity"
        label="Enter B.Qty"
        rules={[
          {
            required: true,
            message: "Please add B-Quantity!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your B-Quantity"
        />
      </Form.Item>

      <Form.Item
        name="variance"
        label="Enter S.Var"
        rules={[
          {
            required: true,
            message: "Please add S-Variance!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your S-Variance"
        />
      </Form.Item>
      <Form.Item
        name="quantity"
        label="Enter S.Qty"
        rules={[
          {
            required: true,
            message: "Please add S-Quantity!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your S-Quantity"
        />
      </Form.Item>

      <Form.Item
        name="marginVariance"
        label="Enter M.Var"
        rules={[
          {
            required: true,
            message: "Please add M-Variance!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your M-Variance!"
        />
      </Form.Item>
      <Form.Item
        name="marginQunatity"
        label="Enter M.Qty"
        rules={[
          {
            required: true,
            message: "Please add M-Quantity!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your M-Quantity"
        />
      </Form.Item>

      <Form.Item
        name={"stratergy"}
        label="Select Stratergy"
        rules={[
          {
            required: true,
            message: "Stratergy is required",
          },
        ]}
      >
        <Select placeholder="Select Stratergy">
          <Select.Option value="N">Neutral SELL</Select.Option>
          <Select.Option value="AV">Average VWAP</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="sl"
        label="Enter the SL"
        rules={[
          {
            required: true,
            message: "Please add your SL!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your SL"
        />
      </Form.Item>
      <Form.Item
        name="target"
        label="Enter target"
        rules={[
          {
            required: true,
            message: "Please add Target!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your Target"
        />
      </Form.Item>
      <Form.Item
        name="shiftPrice"
        label="Enter Shift Price"
        rules={[
          {
            required: true,
            message: "Please add Shift Price!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your Shift Price"
        />
      </Form.Item>
      <Form.Item
        name={"isLiveToTrade"}
        label="Select Live Order"
        rules={[
          {
            required: true,
            message: "Live is required",
          },
        ]}
      >
        <Select placeholder="Is Order is Online">
          <Select.Option value={true}>Live</Select.Option>
          <Select.Option value={false}>Offline</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={"status"}
        label="Select Status"
        rules={[
          {
            required: true,
            message: "Status is Required",
          },
        ]}
      >
        <Select placeholder="Select status">
          <Select.Option value={"PENDING"}>PENDING</Select.Option>
          <Select.Option value={"WAITING"}>WAITING</Select.Option>
          <Select.Option value={"SL"}>SL</Select.Option>
          <Select.Option value={"BUY"}>BUY</Select.Option>
          <Select.Option value={"SELL"}>SELL</Select.Option>
          <Select.Option value={"LIMIT"}>LIMIT</Select.Option>
          <Select.Option value={"BUY_SL"}>BUY-SL</Select.Option>
          <Select.Option value={"SELL_SL"}>SELL-SL</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={"_id"} style={{ height: "1px" }}></Form.Item>
    </Form>
  );
}

export default CreateForm;
