import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RouterConfig } from "./routes/routesConfig";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { verify } from "./utils/api";

function App() {
  const isAuthenticated = verify() || false;
  const clientID = process.env.REACT_APP_CLIENT_ID;

  return (
    <GoogleOAuthProvider clientId={clientID}>
      <Router>
        <Routes>
          {RouterConfig(isAuthenticated).map(({ path, element, children }) => (
            <Route key={path} path={path} element={element}>
              {isAuthenticated &&
                children?.map(({ path: childPath, element: childElement }) => (
                  <Route
                    key={childPath}
                    path={childPath}
                    element={childElement}
                  />
                ))}
            </Route>
          ))}
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
