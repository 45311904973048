import * as Pages from "./../pages";
import { Navigate } from "react-router-dom";

const { Dashboard, Login, ...privateRoutes } = Pages;

export const RouterConfig = (isAuthenticated) => [
  {
    path: "/",
    element: isAuthenticated ? <Dashboard /> : <Login />,
    children: [
      ...Object.keys(privateRoutes).map((routes) => {
        const Element = privateRoutes[routes];
        return {
          path: `/${routes.toLocaleLowerCase()}`,
          element: <Element />,
        };
      }),
      {
        path: `*`,
        element: <Navigate to={"/"} />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default { RouterConfig };
