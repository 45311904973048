import { useEffect, useState } from "react";
import { listUsers, getBalance } from "../../utils/api";
import "./style.css";

function Balance() {
  const [currentUser, setCurrentUser] = useState(null);
  const [user, setUsers] = useState([]);
  const [balance, setBalance] = useState({});

  useEffect(() => {
    async function fetch() {
      const users = await listUsers();
      setUsers(users);
      setCurrentUser(users?.[0]?._id);
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      const balance = await getBalance(currentUser);
      setBalance(balance);
    }
    fetch();
  }, [currentUser]);

  return (
    <div className="order-container">
      <div className="order-sub-container">
        <div className="users-list">
          {user?.map((data) => {
            return (
              <button
                className={`custom ${
                  currentUser === data._id ? "activeButton" : ""
                }`}
                onClick={() => setCurrentUser(data._id)}
              >
                {data.name}
              </button>
            );
          })}
        </div>
      </div>
      <div className="table-content">
        <div className="funds">
          <div className="keys">
            <div className="data">Account Balance</div>
            <div className="data">
              <h4>Opening Balance</h4>
            </div>
            <div className="data">
              <h4>Available Margin</h4>
            </div>
            <div className="data">
              <h4>Available Cash</h4>
            </div>
          </div>
          <div className="keys">
            <div className="data transp">Account Balance</div>
            <div className="data">
              <h4 className="price">
                {balance?.equity?.available?.opening_balance?.toLocaleString(
                  "en-IN"
                ) || "-"}
              </h4>
            </div>
            <div className="data">
              <h4 className="price">
                {balance?.equity?.available?.live_balance?.toLocaleString(
                  "en-IN"
                ) || "-"}
              </h4>
            </div>
            <div className="data">
              <h4 className="price">
                {balance?.equity?.available?.cash?.toLocaleString("en-IN") ||
                  "-"}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Balance;
