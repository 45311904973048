import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Popconfirm } from "antd";
import "./style.css";
import C from "./constant";
import cIcon from "./assets/company.svg";

function Dashboard() {
  const [nav, setNav] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [pl, setPl] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (location.pathname === "/") handleNavigation("/live");
    setNav(location.pathname);
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    const eventSource = new EventSource(process.env.REACT_APP_PL_SERVER);
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data?.message?.length) {
        setData(data.message);
        const total = data?.message?.reduce((a, b) => a + b?.total, 0) || 0;
        setPl(total);
        document.title = `Total Pl: ${total.toLocaleString()}`;
      }
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const handleNavigation = (to) => navigate(to);

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <div className="container">
      <div className="header">
        <div className="header-nav">
          <div className="header-section">
            <img src={cIcon} className="company-logo" alt="log" />
            <div className="pl-section">
              {nav !== "/live" && (
                <h3 className="pl">
                  Total P&L : &nbsp;
                  <span className={`${pl < 0 ? "loss" : "profit"}`}>
                    {pl < 0 ? "" : "+"}
                    {pl.toLocaleString()}
                  </span>
                </h3>
              )}
            </div>
          </div>
          <div className="header-section2 nav">
            {C.navigationItems.map(({ path, name }) => {
              return (
                <div
                  className={`${
                    nav === path ? "nav-items active" : "nav-items"
                  }`}
                  onClick={() => handleNavigation(path)}
                >
                  {name}
                </div>
              );
            })}
            <Popconfirm
              title="Logout from Application"
              description="Are you sure to Logout?"
              onConfirm={handleLogout}
              okText="Yes"
            >
              <div className={`nav-items`}>Logout</div>
            </Popconfirm>
          </div>
        </div>
      </div>

      <div className="content">
        <Outlet context={{ pl, users: data }} />
      </div>
    </div>
  );
}

export default Dashboard;
